/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { motion } from "framer-motion"
import showdown from "showdown"
import ReactHtmlParser from "react-html-parser"

import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit.js"

import transition from "../utils/framer-transition"

const imageMotionVariants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { ...transition },
  },
}

const Image = props => {
  const {
    url,
    useRatio,
    imgixParams,
    ratio,
    altText,
    imageSx,
    containerSx,
    autoFormat,
    credit,
  } = props

  const imageWidths = [375, 700, 750, 1180, 1440, 1800, 2100, 2400, 2880]

  const generateTransformedImageUrl = width => {
    const splitRatio = useRatio ? String(ratio).split(":") : null
    const heightModifer = useRatio ? splitRatio[1] / splitRatio[0] : null

    return `${url}?fit=crop${
      autoFormat ? "&auto=format" : ""
    }${imgixParams}&w=${width}${
      heightModifer !== null ? "&h=" + Math.ceil(width * heightModifer) : ""
    }`
  }

  const converter = new showdown.Converter()
  const CreditContent = ReactHtmlParser(converter.makeHtml(credit))

  return (
    <motion.div
      variants={imageMotionVariants}
      sx={{
        overflow: "hidden",
        position: "relative",
        ...containerSx,
      }}
    >
      <img
        src={generateTransformedImageUrl(16)}
        data-srcset={imageWidths.map(
          width => generateTransformedImageUrl(width) + ` ${width}w`
        )}
        data-sizes="auto"
        className="lazyload"
        alt={altText}
        sx={{
          position: "relative",
          zIndex: 1,
          "&[data-sizes=auto]": {
            display: "block",
            width: "100%",
          },
          ...imageSx,
        }}
      />

      {credit && (
        <div
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            color: "#fff",
            zIndex: 10,
            p: {
              fontSize: 0,
              lineHeight: "body",
              fontWeight: "normal",
              textShadow: "rgba(0,0,0,0.4) 0 1px 2px",
            },
            a: {
              variant: "text.link",
              "&:hover": {
                color: "accent",
              },
            },
          }}
        >
          {CreditContent}
        </div>
      )}
    </motion.div>
  )
}

Image.defaultProps = {
  ratio: "16:9",
  useRatio: true,
  autoFormat: false,
  imgixParams: ``,
}

export default Image
